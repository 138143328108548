import { useEffect, useRef } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import type { FormWithReCaptchaProps } from '@/components/common/form/FormWithReCaptcha/FormWithReCaptcha.types';
import { useFormContext } from '@/components/common/form/context/FormContext';
import { FORM_ACTIONS } from '@/components/common/form/context/constants';
import { CSRFTokenField } from '@/components/common/formFields/CSRFTokenField/CSRFTokenField';
import { useNotifications } from '@/hooks/useNotifications/useNotifications';
import { config } from '@/config';

export const FormWithReCaptcha = (props: FormWithReCaptchaProps) => {
  const { reCaptcha: { siteKey, enabled } } = config;

  if (!siteKey) {
    throw new Error('You should provide a "reCaptcha" site key in the config file.');
  }

  if (!enabled) {
    return <FormWrapper {...props} />;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
      <FormWithReCaptchaInner {...props} />
    </GoogleReCaptchaProvider>
  );
};

const FormWithReCaptchaInner = ({ children, form } : FormWithReCaptchaProps) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { dispatchNotification } = useNotifications();
  const reCaptchaInputRef = useRef<HTMLInputElement>(null);
  const { dispatch } = useFormContext();

  return (
    <FormWrapper
      {...form}
      form={{
        ...form,
        onSubmit: async (e) => {
          dispatch({ type: FORM_ACTIONS.SET_SUBMITTING, payload: true });

          try {
            reCaptchaInputRef.current.value = (await executeRecaptcha('submit'));
          } catch (e) {
            dispatchNotification({
              variant: 'error',
              children: e.message,
            });

            dispatch({ type: FORM_ACTIONS.SET_SUBMITTING, payload: false });
            return false;
          }

          form?.onSubmit?.(e);

          return true;
        },
      }}
    >
      <input type={'hidden'} name={'g-recaptcha-response'} ref={reCaptchaInputRef} />
      {children}
    </FormWrapper>
  );
};

export const FormWrapper = ({ children, form, forceSubmit } : FormWithReCaptchaProps) => {
  const { trigger, dispatch } = useFormContext();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (forceSubmit && formRef.current) {
      // TODO: Find other way to trigger the form
      setTimeout(() => {
        formRef.current.submit();
      }, 500);
    }
  }, [forceSubmit]);

  return (
    <form
      {...form}
      ref={formRef}
      onSubmit={async (e) => {
        e.preventDefault();
        dispatch({ type: FORM_ACTIONS.SET_SUBMITTING, payload: true });

        const isValidated = await trigger();

        if (!isValidated) {
          dispatch({ type: FORM_ACTIONS.SET_SUBMITTING, payload: false });
          return false;
        }

        const submitHandler = (await form?.onSubmit?.(e)) as unknown;

        if (submitHandler !== false) {
          formRef.current?.submit();
        }
      }}
    >
      <CSRFTokenField />
      {children}
    </form>
  );
};
